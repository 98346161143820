import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["table", "mergeCheckbox"]

  connect() {}

  handleMerge = () => {
    const idsToMerge = []
    this.mergeCheckboxTargets.forEach(function (box) {
      if (box.checked) {
        idsToMerge.push(box.value)
      }
    })

    fetch("/admin/issuers/merge", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": jQuery('meta[name="csrf-token"]').attr("content")
      },
      body: JSON.stringify(idsToMerge)
    }).then((response) => location.reload(true))
  }
}
