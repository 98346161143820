import autocomplete from "autocomplete.js"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "input",
    "toggle",
    "submit",
    "displayAfterCertificateSelection",
    "hideAfterSelection",
    "selectionName",
    "issuerName",
    "credentialID",
    "document",
    "issuerNameInput",
    "showError"
  ]
  static values = {
    credentialType: String,
    currentUser: String,
    credentialSelected: Boolean
  }

  connect() {
    if (this.inputTarget.value !== "") {
      // Already have an input, form is being re-rendered bc of a submission validation issue,
      // hide the autosuggest and just show selection name instead
      $(this.hideAfterSelectionTarget).hide()
      $(this.displayAfterCertificateSelectionTarget).show()
    } else {
      if (this.displayAfterCertificateSelectionTarget) {
        this.displayAfterCertificateSelectionTarget.style.display = "none"
      }
    }

    this.suggestionsAmountToShow = this.data.get("matchesToShow") || 10
    this.setupAutosuggest()
    this.disableSubmitButton()
    this.credentialSelectedValue = false
  }

  hide() {
    this.toggleTarget.classList.add("hidden")
  }

  setupAutosuggest = () => {
    const controller = this

    autocomplete(
      `#${controller.inputTarget.id}`,
      {
        hint: false,
        clearOnSelected: true
      },
      [
        {
          source: (query, callback) => {
            const pattern = new RegExp(query, "gi")
            const tempResults = App[controller.data.get("source")].reduce(
              (a, o) => (o.state === "approved" && o.kind === "certificate" && a.push(o), a),
              []
            )
            const results = tempResults
              .filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
              .map((item) => {
                item.highlight = item.name.replace(pattern, "<em>$&</em>")
                return item
              })

            callback(results)
          },
          displayKey: "name",
          templates: {
            empty: function () {
              return `<button class= "no-results-btn" id="no-results" type="button" data-action="click->certificate#noResultsButtonClicked"><i class= "icon-add"/> Don't see your certificate? Add it now.</a>`
            },
            suggestion: function (suggestion) {
              return suggestion.highlight
            }
          }
        }
      ]
    ).on("autocomplete:selected", function (event, suggestion, dataset, empty) {
      controller.selectionNameTarget.textContent = suggestion.name
      $("input#certificateID").val(suggestion.id)
      $(controller.hideAfterSelectionTarget).fadeOut(() => {
        $(controller.displayAfterCertificateSelectionTarget).fadeIn()
      })
      if (suggestion.issuer_id != null) {
        $("input#CertificateIssuerID").val(suggestion.issuer_id)
        document.getElementById(`displaySelectedCertificateIssuer`).innerHTML = suggestion.issuer_name
        // document.getElementById(`Certificate_issuer_id`).value = "";
        // document.getElementById(`Certificate_issuer_id`).innerHTML = "";
        $("#hideAfterCertificateIssuerSelect").fadeOut(() => {
          document.getElementById("edit_certificate_button").classList.add("hidden")
          $(`#displayAfterCertificateIssuerSelect`).fadeIn()
        })
      }
      setTimeout(function () {
        controller.formIsChanged()
      }, 1000)
    })
  }

  noResultsButtonClicked() {
    const tempName = this.inputTarget.value
    $.ajax({
      url: "/credentials_requests",
      data: {
        credential: {
          name: this.inputTarget.value,
          kind: "certificate",
          creator_id: this.currentUserValue
        }
      },
      dataType: "script",
      method: "post",
      success: function (data) {
        $("input#certificateID").val(data)
        document.getElementById("displaySelectedCertificate").innerHTML = tempName
        $("#hideAfterCertificateSelect").fadeOut(() => {
          $("#displayAfterCertificateSelect").fadeIn()
        })
      }
    })
    setTimeout(() => {
      this.formIsChanged()
    }, 1000)
  }

  displayCredentialInputs = () => {
    $("#displayAfterCertificateSelect").fadeOut(() => {
      $("#hideAfterCertificateSelect").fadeIn()
    })
    if (this.issuerNameInputTarget.value === "" && $("input#CertificateIssuerID").val !== "") {
      $(`#displayAfterCertificateIssuerSelect`).fadeOut(() => {
        $(`#hideAfterCertificateIssuerSelect`).fadeIn()
      })
    }
    document.getElementById("edit_certificate_button").classList.remove("hidden")
    this.disableSubmitButton()
  }

  formIsChanged() {
    const certificateNotUploaded =
      document.getElementById(`displayAfterCertificateSelect`).style.cssText === "display: none;"
    const documentNotUploaded =
      window.getComputedStyle(document.getElementById(`certificateUploaded`), null).display === "none"
    const issuerNotUploaded = document.getElementById(`displaySelectedCertificateIssuer`).textContent.length === 0

    if (certificateNotUploaded || documentNotUploaded || issuerNotUploaded) {
      this.disableSubmitButton()
    } else {
      this.enableSubmitButton()
    }
  }

  disableSubmitButton = () => {
    this.submitTarget.disabled = true
    this.credentialSelectedValue = false
  }

  enableSubmitButton = () => {
    this.submitTarget.disabled = false
    this.credentialSelectedValue = true
  }

  noSelectError() {
    if (
      (this.inputTarget.value !== "" && $("input#certificateID").val.length < 2) ||
      this.credentialSelectedValue === false
    ) {
      this.showErrorTarget.classList.remove("hidden")
      this.disableSubmitButton()
    }
  }
}
