import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "submit"]

  connect() {
    this.submitTarget.disabled = true
  }

  select = (e) => {
    $(this.element).find(".card.selected").removeClass("selected")
    const option = e.currentTarget.dataset.type
    this.inputTarget.value = option
    e.currentTarget.classList.add("selected")
    this.submitTarget.disabled = false
  }
}
