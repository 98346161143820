import NiceModal from "@ebay/nice-modal-react"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { AppointmentProvider } from "../contexts/AppointmentContext"
import { ToastProvider } from "../contexts/ToastContext"

import GraphQLProvider from "./GraphQLProvider"

const DefaultProvider = ({ children }) => (
  <Router>
    <NiceModal.Provider>
      <ToastProvider>
        <AppointmentProvider>
          <GraphQLProvider>{children}</GraphQLProvider>
        </AppointmentProvider>
      </ToastProvider>
    </NiceModal.Provider>
  </Router>
)

export default DefaultProvider
