import Sortable from "sortablejs"
import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    $.ajax({
      url: this.data.get("url").replace(":id", id),
      method: "patch",
      data: { position: event.newIndex + 1 }
    })
  }
}
