import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "error", "success"]
  static values = {
    vanitySlugs: Array,
    allSlugs: Array,
    currentVanitySlug: String,
    currentSlug: String
  }

  connect() {
    this.change()
  }

  change() {
    if (this.fieldTarget.value === "") {
      this.clearWarnings()
    } else {
      const regex = /[^A-Za-z0-9_-]/g
      const slug = this.fieldTarget.value
      const cleanerSlug = slug.replace(regex, "")
      this.fieldTarget.value = cleanerSlug
    }
    if (
      (this.vanitySlugsValue.includes(this.fieldTarget.value) &&
        this.fieldTarget.value !== this.currentVanitySlugValue) ||
      (this.allSlugsValue.includes(this.fieldTarget.value) && this.fieldTarget.value !== this.currentSlugValue)
    ) {
      this.fieldTarget.classList.add("is-danger")
      this.errorTarget.classList.remove("hidden")
      this.successTarget.classList.add("hidden")
      const settingsButton = document.getElementById("update-settings")
      if (settingsButton) {
        settingsButton.disabled = false
      }
    } else if (
      !this.vanitySlugsValue.includes(this.fieldTarget.value) &&
      !this.allSlugsValue.includes(this.fieldTarget.value) &&
      this.fieldTarget.value !== this.currentVanitySlugValue
    ) {
      this.fieldTarget.classList.add("is-success")
      this.fieldTarget.classList.remove("is-danger")
      this.errorTarget.classList.add("hidden")
      this.successTarget.classList.remove("hidden")
      const settingsButton = document.getElementById("update-settings")
      if (settingsButton) {
        settingsButton.disabled = false
      }
    } else {
      this.clearWarnings()
    }
  }

  clearWarnings() {
    this.fieldTarget.classList.remove("is-danger")
    this.fieldTarget.classList.remove("is-success")
    this.errorTarget.classList.add("hidden")
    this.successTarget.classList.add("hidden")
    const settingsButton = document.getElementById("update-settings")
    if (settingsButton) {
      settingsButton.disabled = false
    }
  }
}
