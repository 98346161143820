import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["showDecline", "declineButton"]
  static values = { disableButtonState: Boolean }

  connect() {}

  toggle = (e) => {
    e.preventDefault()
    if (this.disableButtonStateValue == false) {
      this.declineButtonTarget.classList.remove("hidden")
      this.disableButtonStateValue = true
    } else {
      this.declineButtonTarget.classList.add("hidden")
      this.disableButtonStateValue = false
    }
  }
}
