import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "fullName",
    "continue",
    "personalDetails",
    "completeProfile",
    "genderSelector",
    "customGenderColumn"
  ]

  connect() {
    $(this.completeProfileTarget).hide()
    this.customGenderColumnTarget.classList.add("hidden")
  }

  updateFullName = (e) => {
    e.preventDefault()
  }

  continue = (e) => {
    var arr = this.fullNameTarget.value.split(" ")
    if (arr.length > 1) {
      $(this.personalDetailsTarget).fadeOut(() => {
        $(this.completeProfileTarget).fadeIn()
      })
    } else {
      const errorMessage = `Please enter your full name.`
      $("<span></span>").addClass("error").text(errorMessage).insertAfter(this.fullNameTarget).delay(5000).fadeOut()
    }
  }

  genderSelect = (event) => {
    if (event.target.value == "Custom") {
      this.customGenderColumnTarget.classList.remove("hidden")
    } else {
      this.customGenderColumnTarget.classList.add("hidden")
      $("#customGenderID").val("")
    }
  }
}
