import algoliasearch from "algoliasearch"
import autocomplete from "autocomplete.js"
import { Controller } from "stimulus"

// Note - if Max wants preloaded options, see algolia autocomplete's Query Suggestions feature

const client = algoliasearch("32OFKS54G6", "d1b54ad44b01012ff167435dae6fe2a5")
const specialtyIndex = client.initIndex("Specialty")
const userIndex = client.initIndex(`User_${process.env.NODE_ENV}`)

export default class extends Controller {
  static targets = ["autocompleteSelections", "filterSelections", "virtualSelector", "locationField"]

  connect() {
    if (!$("html").hasClass("google-places-injected")) {
      this.setupField()
    }
  }

  setupField = () => {
    const controller = this
    const placesAutocomplete = new google.maps.places.Autocomplete(this.locationFieldTarget, {
      types: ["(regions)"]
    })

    placesAutocomplete.addListener("place_changed", () => {
      const place = placesAutocomplete.getPlace()
      $("input#search_lat").val(place.geometry.location.lat())
      $("input#search_lng").val(place.geometry.location.lng())
    })
    $("html").addClass("google-places-injected")
  }
  submitForm(e) {
    e.preventDefault()
    this.formTarget.submit()
  }

  triggerSearch = (e) => {
    e.preventDefault()
    const currentLat = this.data.get("defaultLat")
    const currentLng = this.data.get("defaultLng")
    const defaultCity = this.data.get("defaultCity")
    let url_string = "/practitioner-search?query="
    if ($("input#search_lat").val()) {
      url_string +=
        "&aroundLatLng[lat]=" + $("input#search_lat").val() + "&aroundLatLng[lng]=" + $("input#search_lng").val()
    } else if (currentLat && currentLng) {
      url_string += "&aroundLatLng[lat]=" + currentLat + "&aroundLatLng[lng]=" + currentLng
    }
    if ($("input#search_location").val()) {
      url_string += "&searched_location=" + encodeURIComponent($("input#search_location").val())
    } else if (defaultCity) {
      url_string += "&searched_location=" + defaultCity
    }
    window.location.href = url_string
  }
}
