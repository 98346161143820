import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["genderSelector", "customGenderColumn"]
  static values = {
    gender: String
  }

  connect() {
    if (this.genderValue != "") {
      this.customGenderColumnTarget.classList.remove("hidden")
    } else {
      this.customGenderColumnTarget.classList.add("hidden")
    }
  }

  genderSelect = (event) => {
    if (event.target.value == "Custom") {
      this.customGenderColumnTarget.classList.remove("hidden")
    } else {
      this.customGenderColumnTarget.classList.add("hidden")
      $("#customGenderID").val("")
    }
  }
}
