import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["timeInput", "bookingButton", "submitButton"]
  static values = { timeSelected: Boolean, dateTime: Array }

  connect() {
    if (this.hassubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }
  }

  select = (e) => {
    e.preventDefault()

    const link = $(e.currentTarget)
    if (link[0].classList.value.includes("selected")) {
      link.removeClass("selected")
      const dateTime = link.attr("datetime") ? link.attr("datetime") : link.find("time").attr("datetime")
      const arr = this.dateTimeValue
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == dateTime) {
          arr.splice(i, 1)
        }
      }
      this.dateTimeValue = arr
      if (arr.length == 3) {
        this.timeSelectedValue = true
        this.submitButtonTarget.disabled = false
      } else {
        this.timeSelectedValue = false
        this.submitButtonTarget.disabled = true
      }
    } else {
      link.addClass("selected")
      const dateTime = link.attr("datetime") ? link.attr("datetime") : link.find("time").attr("datetime")
      const arr = this.dateTimeValue
      arr.push(dateTime)
      this.dateTimeValue = arr
      if (arr.length == 3) {
        this.timeSelectedValue = true
        this.submitButtonTarget.disabled = false
      } else {
        this.timeSelectedValue = false
        this.submitButtonTarget.disabled = true
      }
    }
    var selectedList = $(this.element).find("a.selected")
    if (selectedList.length == 3) {
      var buttons = $(this.element).find("a")
      buttons.each(function (btn) {
        if (!this.classList.value.includes("selected")) {
          this.classList.value = "inactive-link"
        }
      })
    } else {
      $(this.element).find("a.inactive-link").removeClass("inactive-link")
    }
    $(this.timeInputTarget).val(this.dateTimeValue)
  }

  selectOtherTime = (e) => {
    e.preventDefault()
    const link = $(e.currentTarget)

    $(this.element).find("a.selected").removeClass("selected")
    link.addClass("selected")
    const dateTime = link.attr("datetime") ? link.attr("datetime") : link.find("time").attr("datetime")
    $(this.timeInputTarget).val(dateTime)
    this.timeSelectedValue = true
    $(this.bookingButtonTarget).fadeIn()
  }

  submitSelection = (e) => {
    if (this.timeSelectedValue) {
      let close_btn = document.getElementById("modal-close-button")
      close_btn.click()
    }
  }
}
