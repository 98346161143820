import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quote", "name", "title", "image", "information"]

  connect() {
    this.element["profileEncouragement"] = this
  }

  animate = (opts) => {
    const transitionClass = "transition-state"

    this.informationTarget.addEventListener("transitionend", () => {
      this.quoteTarget.textContent = opts.quote.quote
      this.nameTarget.textContent = opts.quote.name
      this.titleTarget.textContent = opts.quote.title
    })

    this.informationTarget.classList.add(transitionClass)

    const newImage = document.createElement("img")
    newImage.src = opts.image
    newImage.classList.add(transitionClass)
    newImage.addEventListener("transitionend", () => {
      this.informationTarget.classList.remove(transitionClass)
    })

    this.imageTarget.insertBefore(newImage, this.imageTarget.firstChild)

    newImage.onload = () => {
      newImage.classList.remove(transitionClass)
    }
  }
}
