import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {}

  start = (e) => {
    e.preventDefault()
    $(e.target).fadeOut(100, () => {
      $(this.formTarget).slideDown()
    })
  }

  close = (e) => {
    e.preventDefault()
    $(this.element).addClass("animate__bounceOutRight")
  }
}
