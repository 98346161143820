// used in views/admin/credentials
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["coupon"]
  static values = {
    token: String
  }

  connect() {}

  applyCoupon = (event) => {
    event.preventDefault()
    $.ajax({
      url: `/bookings/${this.tokenValue}/customer_coupon`,
      data: { appointment: { customer_coupon: this.couponTarget.value } },
      dataType: "script",
      method: "patch",
      success: function (data) {}
    })
  }
}
