import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.cardSelector = $(this.element)
    this.hideStripe()
  }

  hideStripe = () => {
    this.cardSelector.find(".stripe-container").hide()
  }

  selectSaved = (e) => {
    this.cardSelector.find(".card.selected").removeClass("selected")
    $(e.currentTarget).addClass("selected")
    const token = $(e.currentTarget).attr("data-card-id")
    $(this.inputTarget).val(token)
  }

  selectNew = (e) => {
    this.cardSelector.find(".stripe-container").show()
    this.cardSelector.find(".card.selected").removeClass("selected")
    $(e.currentTarget).addClass("selected")
    $(this.inputTarget).val("")
  }
}
