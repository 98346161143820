import { Controller } from "stimulus"

window.dispatchPlacesEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-places-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

export default class extends Controller {
  static targets = ["searchInput", "valueInput"]

  connect() {
    const loadedClass = "google-places-injected"

    if (!$("html").hasClass(loadedClass)) {
      const googleApiKey = "AIzaSyBJzO3o3qx-4b53mVm0BA23J1z_Aj7MWmE"
      const googleScriptUrl = `<script defer src="https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=dispatchPlacesEvent&libraries=places"></script>`

      $("head").append(googleScriptUrl)
      $("html").addClass(loadedClass)
    } else {
      this.setupField()

      // A page has a map on it, and google maps is somehow already loaded into the DOM
      dispatchPlacesEvent()
    }
  }

  setupField = () => {
    const controller = this

    const placesAutocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
      fields: ["place_id", "formatted_address", "address_components"]
    })

    // Hack for Chrome Autofill
    // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
    google.maps.event.addDomListener(this.searchInputTarget, "focus", (e) =>
      e.target.setAttribute("autocomplete", "off")
    )
    // To enable selecting Places with the Enter key as well without it Submitting the form.
    $(this.searchInputTarget).keypress(function (e) {
      if (e.which == 13) {
        e.preventDefault()
      }
    })
    placesAutocomplete.addListener("place_changed", () => {
      const place = placesAutocomplete.getPlace()
      controller.valueInputTarget.value = place.place_id
      controller.dispatchChangeEvent()
    })
  }

  dispatchChangeEvent = () => {
    const event = document.createEvent("HTMLEvents")
    event.initEvent("change", true, false)
    this.valueInputTarget.dispatchEvent(event)
  }
}
