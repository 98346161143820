import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    const el = $(this.element)
    const select = this.element.querySelector("select")

    el.find("a").click(function (e) {
      e.preventDefault()
      const item = $(this)
      const val = item.attr("data-value")

      if (item.hasClass("selected")) {
        item.removeClass("selected")
        el.find(`option[value="${val}"]`).prop("selected", false)
      } else {
        if (!el.find("select").attr("multiple")) {
          el.find("a").removeClass("selected")
        }

        item.addClass("selected")
        el.find(`option[value="${val}"]`).prop("selected", "selected")
      }

      el.find("select").change()

      const event = document.createEvent("HTMLEvents")
      event.initEvent("change", true, false)
      select.dispatchEvent(event)
    })
  }
}
