import React from "react"
import ReactDatePicker from "react-datepicker"
import { twMerge } from "tailwind-merge"

import { BASE_INPUT_CLASSNAMES } from "./Inputs"

import "react-datepicker/dist/react-datepicker.css"

const DatePicker = ({ className, ...rest }) => (
  <ReactDatePicker className={twMerge(BASE_INPUT_CLASSNAMES, className)} {...rest} />
)

export default DatePicker
