import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    countires: Boolean,
    states: Boolean
  }

  connect() {}

  change = (event) => {
    if (event.target.value == "I offer worldwide virtual services") {
      document.getElementById("inputSection").classList.add("disabled-input")
    } else {
      document.getElementById("inputSection").classList.remove("disabled-input")
    }
  }
}
