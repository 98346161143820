import algoliasearch from "algoliasearch"
import autocomplete from "autocomplete.js"
import { Controller } from "stimulus"

const client = algoliasearch("32OFKS54G6", "d1b54ad44b01012ff167435dae6fe2a5")
const index = client.initIndex(`Article_${process.env.NODE_ENV}`)

export default class extends Controller {
  static targets = ["autocompleteSelections", "filterSelections", "allFilters", "locationField", "insuranceSelector"]

  connect() {
    this.setupSpecialtySearch()
  }

  // autocomplete.js (v0.38) is not compatible with the latest version algoliasearch v4 out of the box,
  // this next function create a compatible source that we can use in autocomplete()
  newHitsSource = (index, params) => {
    return function doSearch(query, cb) {
      index
        .search(query, params)
        .then(function (res) {
          cb(res.hits, res)
        })
        .catch(function (err) {
          console.error(err)
          cb([])
        })
    }
  }

  setupSpecialtySearch = () => {
    const controller = this

    autocomplete(
      "#search_article",
      {
        hint: false,
        clearOnSelected: true
      },
      [
        {
          source: this.newHitsSource(index, { hitsPerPage: 10 }),
          name: "Articles",
          displayKey: "name",
          templates: {
            header: function (context) {
              return "<span class='header'>Articles</span>"
            },
            empty: function (context) {
              return "<span class='no-results'>No articles found.</span>"
            },
            suggestion: function (suggestion) {
              return suggestion._highlightResult.title.value
            }
          }
        }
      ]
    ).on("autocomplete:selected", function (event, suggestion, dataset, context) {
      if (suggestion.slug) {
        Turbolinks.visit(`/articles/${suggestion.slug}`)
      }
    })
  }

  preventSubmit = (event) => {
    event.preventDefault()
    return false
  }
}
