import { Combobox } from "@headlessui/react"
import algoliasearch from "algoliasearch"
import React, { useRef, useState } from "react"
import { Configure, InstantSearch, useHits, useSearchBox } from "react-instantsearch"

import { useContainerDimensions } from "../../hooks/useContainerDimensions"
import { sanitize } from "../../utils/utils"

import {
  BASE_INPUT_CLASSNAMES,
  BASE_LABEL_CLASSNAMES,
  DROPDOWN_CLASSNAMES,
  DROPDOWN_OPTION_CLASSNAMES,
  SearchMagnifyingGlass
} from "./Inputs"
import Typography from "./Typography"

const searchClient = algoliasearch(
  process.env.ALGOLIA_HEALME_SEARCH_APPLICATION_ID,
  process.env.ALGOLIA_HEALME_SEARCH_CLIENT_API_KEY
)

export const AlgoliaSearchBox = ({ indexName, explanatorySubtext, ...rest }) => (
  <InstantSearch indexName={indexName} searchClient={searchClient}>
    <Search {...rest} />
    {explanatorySubtext && <Typography variant="micro">{explanatorySubtext}</Typography>}
  </InstantSearch>
)

export const AlgoliaSearchProvider = ({ indexName, children }) => (
  <InstantSearch indexName={indexName} searchClient={searchClient}>
    {children}
  </InstantSearch>
)

const Search = ({ onChange, id, label, hitsPerPage = 10, filters, initialValue, ...rest }) => {
  id ||= "search-" + label.toLowerCase().replace(" ", "-")
  const { query, refine } = useSearchBox()
  const { hits } = useHits()
  const [inputValue, setInputValue] = useState(query)
  const ref = useRef()
  const { width } = useContainerDimensions(ref)

  const setQuery = (newQuery) => {
    setInputValue(newQuery)
    refine(newQuery)
  }

  return (
    <>
      <Configure hitsPerPage={hitsPerPage} filters={filters} />
      <Combobox
        value={inputValue}
        onChange={(hit) => {
          setInputValue(hit)
          onChange(hit)
        }}>
        {label && (
          <Combobox.Label htmlFor={id} className={BASE_LABEL_CLASSNAMES}>
            {label}
          </Combobox.Label>
        )}
        <div className="relative">
          <Combobox.Input
            id={id}
            ref={ref}
            {...rest}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(hit) => hit.name || inputValue || initialValue}
            className={BASE_INPUT_CLASSNAMES}
          />
          <SearchMagnifyingGlass />
        </div>
        <Combobox.Options className={"fixed " + DROPDOWN_CLASSNAMES} style={{ maxWidth: width }}>
          {hits.map((hit) => (
            <Combobox.Option key={hit.objectID} value={hit} className={DROPDOWN_OPTION_CLASSNAMES}>
              <div dangerouslySetInnerHTML={{ __html: sanitize(hit.name) }} />
            </Combobox.Option>
          ))}
          {hits.length === 0 && <div className="px-3">No results found</div>}
        </Combobox.Options>
      </Combobox>
    </>
  )
}
