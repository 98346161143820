// used in views/admin/issuers
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name", "nameAbbreviation", "kind"]

  connect() {
    this.updateLongName()
  }

  updateLongName() {
    var longName = this.nameTarget.value
    if (this.nameAbbreviationTarget.value != "") {
      longName = longName + " " + "(" + this.nameAbbreviationTarget.value + ")"
    }
    $("input#issuer_long_name").val(longName)
  }
}
