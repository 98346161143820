import React, { createContext, useContext, useState } from "react"

export const AppointmentContext = createContext()

export const AppointmentProvider = ({ children }) => {
  const [appointment, setAppointment] = useState(null)

  return <AppointmentContext.Provider value={{ appointment, setAppointment }}>{children}</AppointmentContext.Provider>
}

export const useAppointment = () => useContext(AppointmentContext)
