import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card", "submit", "error", "tokenInput", "paymentMethod"]
  static values = {
    currentMethod: String
  }
  connect() {
    this.stripeKey = this.element.dataset.key
    this.intent = this.element.dataset.intent
    this.paymentMethodCheck()
    this.stripe = Stripe(this.stripeKey)
    this.elements = this.stripe.elements()
    this.cardElement = this.elements.create("card")
    this.cardElement.mount(this.cardTarget)

    this.formTarget = this.element

    this.cardElement.addEventListener("change", (event) => {
      if (event.error) {
        this.errorTarget.textContent = event.error.message
      } else {
        this.errorTarget.textContent = ""
      }
    })

    this.element.addEventListener("submit", (e) => {
      e.preventDefault()

      if ($(this.submitTarget).hasClass("processing")) {
        return false
      }

      $(this.submitTarget).addClass("working")
      $(this.submitTarget).addClass("processing")
      if (this.currentMethodValue == "Pay now on Heal.me") {
        if ($("#checkout_saved_card_id").length && $("#checkout_saved_card_id").val().length) {
          $(this.formTarget).submit()
        } else {
          this.stripe
            .createPaymentMethod({
              type: "card",
              card: this.cardElement
            })
            .then((result) => {
              if (result.error) {
                this.errorTarget.textContent = result.error.message
                this.submitTarget.classList.remove("working")
                this.submitTarget.classList.remove("processing")
                return false
              } else {
                $(this.tokenInputTarget).val(result.paymentMethod.id)
                $(this.formTarget).submit()
              }
            })
        }
      } else {
        $(this.formTarget).submit()
      }
    })
  }

  paymentMethodCheck() {
    if (this.paymentMethodTarget.value == "Pay now on Heal.me") {
      $("#pay-now").removeClass("hidden")
      $("#pay-now-amount").removeClass("hidden")
      $("#pay-now-hint").removeClass("hidden")
      $("#pay-later").addClass("hidden")
      $("#pay-later-amount").addClass("hidden")
    } else {
      $("#pay-now").addClass("hidden")
      $("#pay-now-amount").addClass("hidden")
      $("#pay-now-hint").addClass("hidden")
      $("#pay-later").removeClass("hidden")
      $("#pay-later-amount").removeClass("hidden")
    }
    this.currentMethodValue = this.paymentMethodTarget.value
  }

  paymentMethodSelected = (event) => {
    if (event.target.value == "Pay now on Heal.me") {
      $("#pay-now").removeClass("hidden")
      $("#pay-now-amount").removeClass("hidden")
      $("#pay-now-hint").removeClass("hidden")
      $("#pay-later").addClass("hidden")
      $("#pay-later-amount").addClass("hidden")
    } else {
      $("#pay-now").addClass("hidden")
      $("#pay-now-amount").addClass("hidden")
      $("#pay-now-hint").addClass("hidden")
      $("#pay-later").removeClass("hidden")
      $("#pay-later-amount").removeClass("hidden")
    }
    this.currentMethodValue = event.target.value
  }
}
