import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "timeInput",
    "submitTooltip",
    "submitTooltipServiceList",
    "submitTooltipFullServiceList",
    "submitTooltipLocationList",
    "submitTooltipFullLocationList",
    "serviceButtonsContainer",
    "serviceButtonsContainerFullList",
    "locationButtonsContainer",
    "locationButtonsContainerFullList"
  ]
  static values = { timeSelected: Boolean }

  connect() {
    this.index = 0
    this.displayCurrentPage()
  }

  hideAllPages = () => {
    $(this.element).find("th").hide()
    $(this.element).find("td").hide()
  }

  displayPage = (i) => {
    this.hideAllPages()
    const baseIndex = i * 4
    this.displayColumn(baseIndex)
    this.displayColumn(baseIndex + 1)
    this.displayColumn(baseIndex + 2)
    this.displayColumn(baseIndex + 3)
  }

  displayCurrentPage = () => {
    this.displayPage(this.index)
  }

  displayColumn = (i) => {
    $(this.element).find(`th[data-index=${i}]`).show()
    $(this.element).find(`td[data-index=${i}]`).show()
  }

  prev = (e) => {
    e.preventDefault()

    if (this.index > 0) {
      this.index -= 1
      this.displayCurrentPage()
    }
  }

  next = (e) => {
    e.preventDefault()
    if (this.index < 2) {
      this.index += 1
      this.displayCurrentPage()
    }
  }

  selectService = (e) => {
    this.serviceSelectedValue = true
    this.locationSelectedValue = false
  }

  selectLocation = (e) => {
    this.locationSelectedValue = true
    this.serviceSelectedValue = false
  }

  select = (e) => {
    e.preventDefault()
    const link = $(e.currentTarget)

    $(this.element).find("a.selected").removeClass("selected")
    link.addClass("selected")
    const dateTime = link.attr("datetime") ? link.attr("datetime") : link.find("time").attr("datetime")
    $(this.timeInputTarget).val(dateTime)
    this.timeSelectedValue = true
    this.submitTooltipTarget.classList.add("hidden")
    document.getElementById("booking-widget-table").style.border = "1px solid #C8D6DF"
  }

  submitBooking = (event) => {
    if (!this.timeSelectedValue) {
      event.preventDefault()
      const submitButton = event.currentTarget
      if ($(".mobile-widget-container").css("display") == "none") {
        this.submitTooltipTarget.classList.remove("hidden")
        document.getElementById("booking-widget-table").style.border = "1px solid #0c3954"
      } else {
        alert("Please select a time")
      }
      setTimeout(() => {
        submitButton.classList.remove("working")
      }, 10)
    }
    if (this.timeSelectedValue) {
      let btn = document.getElementById("submit-appointment-form-button")
      btn.setAttribute("type", "submit")
      btn.click()
    }
  }
}
