import { ChevronRightIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table"
import { twMerge } from "tailwind-merge"

import { SearchInput } from "./Inputs"
import { EmptyStateBox } from "./Layout"

function GlobalFilter({ globalFilter, setGlobalFilter, searchTerm, setSearchTerm, searchPlaceholder }) {
  const initiaValue = setSearchTerm ? searchTerm : globalFilter
  const [value, setValue] = useState(initiaValue)
  const onChange = useAsyncDebounce((value) => {
    if (setSearchTerm) setSearchTerm(value)
    if (setGlobalFilter) setGlobalFilter(value || undefined)
  }, 1000)

  return (
    <SearchInput
      value={value || ""}
      placeholder={searchPlaceholder || "Search"}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

function Table({
  data,
  columns,
  searchType,
  header: Header,
  rowClick,
  hasPagination = true,
  searchTerm,
  setSearchTerm,
  searchPlaceholder,
  showSearch = true
}) {
  const tableOptions = {
    columns,
    data,
    ...(hasPagination ? { initialState: { pageIndex: 0, pageSize: 50 } } : { manualPagination: true })
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage
  } = useTable(tableOptions, useFilters, useGlobalFilter, usePagination)

  const isSmallScreen = window.innerWidth <= 640
  const numberOfColumns = columns.length

  return (
    <div>
      <div className="flex items-start justify-start sm:flex-col">
        {showSearch && (
          <div className="sm:w-full">
            <GlobalFilter
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchPlaceholder={searchPlaceholder}
            />
          </div>
        )}
        <Header />
      </div>
      <div className="mt-8 bg-white sm:mt-2">
        <table className="w-full" {...getTableProps()}>
          <thead className="sm:hidden">
            {headerGroups.map((headerGroup, groupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
                {headerGroup.headers.map((column, columnIndex) => {
                  const key = `${groupIndex}-${columnIndex}` // Compound key

                  return column.hideHeader ? null : (
                    <th
                      className={`${column.className} pb-3 font-manrope text-xs font-bold text-black`}
                      {...column.getHeaderProps()}
                      key={key} // Using the compound key
                    >
                      {column.render("Header")}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          {page.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr
                    className={`relative border-t border-gray-light sm:border-t first:sm:border-t-0 ${
                      searchType === "clients" || searchType === "reviews" ? "hover:cursor-pointer" : ""
                    }`}
                    key={row.original.id}
                    onClick={() => rowClick(row.original)}>
                    {isSmallScreen ? (
                      <td colSpan={numberOfColumns} className="flex items-center justify-between">
                        <div>
                          {row.cells.map((cell, idx) => {
                            const { Header } = cell.column

                            if (Header === "Status" || Header === "Action") return null

                            let className = "text-sm font-normal text-gray-dark"

                            if (Header === "Client") {
                              className = "text-base font-bold"
                            } else if (Header === "Amount" || Header === "Last appt.") {
                              className = "pb-2 text-sm font-normal text-gray-dark"
                            } else if (Header === "Date") {
                              className = "mb-1 text-sm font-normal text-gray-dark"
                            } else if (Header === "Full Name") {
                              className = "pt-2 mb-1 text-base font-bold"
                            }

                            // add extra padding for first and last element
                            if (idx === 0) {
                              className = twMerge(className, "pt-4")
                            } else if (idx === row.cells.length - 1) {
                              className = twMerge(className, "pb-4")
                            }

                            return (
                              <p key={cell.id} className={`mb-1 ${className}`}>
                                {cell.render("Cell")}
                              </p>
                            )
                          })}
                        </div>
                        <ChevronRightIcon className="h-7 w-7 text-black sm:block" />
                      </td>
                    ) : (
                      row.cells.map((cell) => (
                        <td
                          key={cell.id}
                          className={`${cell.column.className} py-4 font-manrope text-[16px] font-normal text-black`}
                          {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))
                    )}
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr className="border-t border-gray-light" />
              <tr>
                <td colSpan="100%">
                  <EmptyStateBox className="mt-4 w-full text-gray-dark">No {searchType} found</EmptyStateBox>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {hasPagination && data.length > 50 && (
        <ul className="mt-4 flex justify-between text-sm sm:pb-52">
          <li onClick={() => previousPage()} disabled={!canPreviousPage}>
            <a className="text-gray-dark">{"<"} Previous</a>
          </li>

          <li onClick={() => nextPage()} disabled={!canNextPage}>
            <a className="text-gray-dark">Next {">"}</a>
          </li>
        </ul>
      )}
    </div>
  )
}

export default function HealMeTable({
  data,
  searchType,
  columns,
  header = React.Fragment,
  rowClick,
  hasPagination = true,
  searchTerm,
  setSearchTerm,
  searchPlaceholder,
  showSearch = true
}) {
  return (
    <>
      {data && (
        <Table
          data={data}
          columns={columns}
          searchType={searchType}
          header={header}
          rowClick={rowClick}
          hasPagination={hasPagination}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchPlaceholder={searchPlaceholder}
          showSearch={showSearch}
        />
      )}
    </>
  )
}
